import React from "react";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import { Flex } from "../elements/Flex";

const ForgotPasswordPage = () => {
  return (
    <Flex bg="pink" px={3} py={[5, 5, 7]}>
      <ForgotPasswordForm />
    </Flex>
  );
};

export default ForgotPasswordPage;
