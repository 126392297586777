import React, { useState } from "react";
import { Form } from "../elements/Form";
import { Input } from "../elements/Input";
import { Text } from "../elements/Text";
import axios from "axios";
import { vendorUUID } from "../utils/constants";
import { Button } from "../elements/Button";
import { navigate } from "gatsby-link";

const ForgotPasswordForm = () => {
  const [state, setState] = useState({
    passwordResetMessage: "",
    validationMessage: "",
    email: "",
    confirmPassword: "",
  });

  const { passwordResetMessage, validationMessage, email } = state;

  const forgotPasswordClicked = (e) => {
    e.preventDefault();

    const { email } = state;
    if (!email) {
      setState({
        validationMessage: "You must enter your email address",
      });
    } else if (email.indexOf("@") === -1 || email.indexOf(".") === -1) {
      setState({
        validationMessage: "Please enter a valid email address",
      });
    } else {
      axios({
        method: "post",
        url: `https://7efpy5nte8.execute-api.eu-west-1.amazonaws.com/prod/reset-account-password`,
        data: {
          username: `${email}#${vendorUUID}`,
          resetRedirectUri: `https://leanwithlilly.com/reset-password/?code=`,
        },
      })
        .then((response) => {
          if (response.data.errorMessage) {
            setState({
              validationMessage: response.data.errorMessage,
            });
          } else {
            setState({
              passwordResetMessage: `An email with instructions on how to reset your password has been sent to ${email}.`,
            });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          setState({
            validationMessage: error.response.data.errorMessage || "Error - We couldn't find your email in our system",
          });
        });
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      email: e.target.value.toLowerCase().replace(" ", ""),
    });
  };

  return (
    <Form
      bg="white"
      py={3}
      px={3}
      textAlign="center"
      width="100%"
      maxWidth="500px"
      mx="auto"
      onSubmit={forgotPasswordClicked}
    >
      {passwordResetMessage ? (
        <Text my={2}>{passwordResetMessage}</Text>
      ) : (
        <>
          <Text my={3} fontSize={25} fontWeight={600}>
            FORGOT PASSWORD
          </Text>

          <Input
            variant="authentication"
            id="email"
            placeholder="Email"
            type="email"
            required
            //   value={formData.email}
            onChange={handleChange}
          />

          {validationMessage && (
            <Text fontWeight={600} color="red">
              {validationMessage}
            </Text>
          )}

          <Button
            bg="pink"
            width="100%"
            color="white"
            p={2}
            border="none"
            borderRadius="5px"
            fontWeight="600"
            onClick={forgotPasswordClicked}
            fontSize={17}
          >
            Reset
          </Button>
          <Text mt={3} cursorPointer underline onClick={() => navigate(-1)}>
            Go back
          </Text>
        </>
      )}
    </Form>
  );
};

export default ForgotPasswordForm;
